import { RouteRecordRaw } from 'vue-router';

const rotasModuloEstoques: Array<RouteRecordRaw> = [
  {
    path: '/estoques',
    name: 'ModuloEstoques',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'mapa',
        name: 'Mapa',
        meta: {
          titulo: 'Mapa dos Estoques',
          identificadorRecurso: 'MAPA_ESTOQUE',
          identificadorPermissao: 'AUT_MAPA_ESTOQUE',
        },
        component: () => import(/* webpackChunkName: "estoques" */ '@/views/Estoques/MapaEstoque/MapaEstoqueIndex.vue'),
      },
      {
        path: 'balancos',
        name: 'Balancos',
        meta: {
          titulo: 'Balanços de Estoque',
          identificadorRecurso: 'BALANCO_ESTOQUE',
          identificadorPermissao: 'AUT_BALANCO_ESTOQUE',
        },
        component: () => import(/* webpackChunkName: "estoques" */ '@/views/Estoques/BalancoEstoque/BalancoEstoqueIndex.vue'),
      },
      {
        path: 'movimentacoes',
        name: 'MovimentacaoEstoque',
        meta: {
          titulo: 'Movimentação de Estoque',
          identificadorRecurso: 'MOVIMENTACAO_ESTOQUE',
          identificadorPermissao: 'AUT_MOVIMENTACAO_ESTOQUE',
        },
        component: () => import(/* webpackChunkName: "estoques" */ '@/views/Estoques/MovimentacaoEstoque/MovimentacaoEstoqueIndex.vue'),
      },
      {
        path: 'transferencias',
        name: 'TransferenciaEstoque',
        meta: {
          titulo: 'Transferências entre Estoques',
          identificadorRecurso: 'TRANSFERENCIA_ENTRE_ESTOQUES',
          identificadorPermissao: 'AUT_TRANSFERENCIA_ENTRE_ESTOQUES',
        },
        component: () => import(/* webpackChunkName: "estoques" */ '@/views/Estoques/TransferenciaEstoque/TransferenciaEstoqueIndex.vue'),
      },
    ],
  },
];

export default rotasModuloEstoques;
