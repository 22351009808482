import { RouteRecordRaw } from 'vue-router';

const rotasModuloVendas: Array<RouteRecordRaw> = [
  {
    path: '/vendas',
    name: 'Vendas',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'processo/:codigoProcesso',
        name: 'ProcessoVendas',
        meta: {
          titulo: 'Vendas',
          identificadorRecurso: 'VENDAS',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Vendas/Vendas/VendasIndex.vue'),
      },
      {
        path: 'devolucoes-vendas',
        name: 'DevolucoesVendas',
        meta: {
          titulo: 'Devoluções de Vendas',
          identificadorRecurso: 'DEVOLUCOES_VENDAS',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Vendas/DevolucoesVendas/DevolucaoVendaIndex.vue'),
      },
      {
        path: 'relatorios/venda-clientes',
        name: 'RelatorioVendaPorCliente',
        meta: {
          titulo: 'Relatório Venda Por Cliente',
          identificadorRecurso: 'RELATORIO_VENDA_CLIENTE',
        },
        // eslint-disable-next-line import/no-unresolved
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Relatorios/Vendas/VendaPorCliente/RelatorioVendaPorCliente.vue'),
      },
      {
        path: 'relatorios/venda-produtos',
        name: 'RelatorioVendaPorProduto',
        meta: {
          titulo: 'Relatório Venda Por Produto',
          identificadorRecurso: 'RELATORIO_VENDA_PRODUTO',
        },
        // eslint-disable-next-line import/no-unresolved
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Relatorios/Vendas/VendaPorProduto/RelatorioVendaPorProduto.vue'),
      },
      {
        path: 'relatorios/margem-de-lucro',
        name: 'RelatorioMargemLucro',
        meta: {
          titulo: 'Relatório Margem de Lucro de Produtos',
          identificadorRecurso: 'RELATORIO_MARGEM_LUCRO',
        },
        // eslint-disable-next-line import/no-unresolved
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Relatorios/Vendas/MargemLucro/RelatorioMargemLucro.vue'),
      },
      {
        path: 'relatorios/analise-desconto',
        name: 'RelatorioAnaliseDesconto',
        meta: {
          titulo: 'Relatório Análise de Desconto',
          identificadorRecurso: 'RELATORIO_ANALISE_DESCONTO',
        },
        // eslint-disable-next-line import/no-unresolved
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Relatorios/Vendas/AnaliseDesconto/RelatorioAnaliseDesconto.vue'),
      },
    ],
  },
];

export default rotasModuloVendas;
