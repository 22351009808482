import { RouteRecordRaw } from 'vue-router';

const rotasModuloCadastros: Array<RouteRecordRaw> = [
  {
    path: '/cadastros',
    name: 'Cadastros',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'paises',
        name: 'Paises',
        meta: {
          titulo: 'Países',
          identificadorRecurso: 'CADASTRO_PAISES',
          identificadorPermissao: 'PER_CADASTRO_PAISES',
        },
        component: () => import(/* webpackChunkName: "cadastros-localizacoes" */ '@/views/Cadastros/Localizacoes/Paises/PaisIndex.vue'),
      },
      {
        path: 'estados',
        name: 'Estados',
        meta: {
          titulo: 'Estados',
          identificadorRecurso: 'CADASTRO_ESTADOS',
          identificadorPermissao: 'PER_CADASTRO_ESTADOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-localizacoes" */ '@/views/Cadastros/Localizacoes/Estados/EstadoIndex.vue'),
      },
      {
        path: 'cidades',
        name: 'Cidades',
        meta: {
          titulo: 'Cidades',
          identificadorRecurso: 'CADASTRO_CIDADES',
          identificadorPermissao: 'PER_CADASTRO_CIDADES',
        },
        component: () => import(/* webpackChunkName: "cadastros-localizacoes" */ '@/views/Cadastros/Localizacoes/Cidades/CidadeIndex.vue'),
      },
      {
        path: 'tipos-contatos',
        name: 'TiposContatos',
        meta: {
          titulo: 'Tipos de Contatos',
          identificadorRecurso: 'CADASTRO_TIPOS_CONTATOS',
          identificadorPermissao: 'PER_CADASTRO_TIPOS_CONTATOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-pessoas" */ '@/views/Cadastros/Pessoas/TiposContatos/TipoContatoIndex.vue'),
      },
      {
        path: 'funcoes-colaboradores',
        name: 'FuncoesColaboradores',
        meta: {
          titulo: 'Funções de colaboradores',
          identificadorRecurso: 'CADASTRO_FUNCOES_COLABORADORES',
          identificadorPermissao: 'PER_CADASTRO_FUNCOES_COLABORADORES',
        },
        component: () => import(/* webpackChunkName: "cadastros-colaboradores" */ '@/views/Cadastros/Pessoas/Colaboradores/FuncaoIndex.vue'),
      },
      {
        path: 'departamentos-colaboradores',
        name: 'DepartamentosColaboradores',
        meta: {
          titulo: 'Departamentos de colaboradores',
          identificadorRecurso: 'CADASTRO_DEPARTAMENTOS_COLABORADORES',
          identificadorPermissao: 'PER_CADASTRO_DEPARTAMENTOS_COLABORADORES',
        },
        component: () => import(/* webpackChunkName: "cadastros-colaboradores" */ '@/views/Cadastros/Pessoas/Colaboradores/DepartamentoIndex.vue'),
      },
      {
        path: 'colaboradores',
        name: 'Colaboradores',
        meta: {
          titulo: 'Colaboradores',
          identificadorRecurso: 'CADASTRO_COLABORADORES',
          identificadorPermissao: 'PER_CADASTRO_COLABORADORES',
        },
        component: () => import(/* webpackChunkName: "cadastros-colaboradores" */ '@/views/Cadastros/Pessoas/Colaboradores/ColaboradorIndex.vue'),
      },
      {
        path: 'clientes',
        name: 'Clientes',
        meta: {
          titulo: 'Clientes',
          identificadorRecurso: 'CADASTRO_CLIENTES',
          identificadorPermissao: 'PER_CADASTRO_CLIENTES',
        },
        component: () => import(/* webpackChunkName: "cadastros-pessoas" */ '@/views/Cadastros/Pessoas/Clientes/ClienteIndex.vue'),
      },
      {
        path: 'fornecedores',
        name: 'Fornecedores',
        meta: {
          titulo: 'Fornecedores',
          identificadorRecurso: 'CADASTRO_FORNECEDORES',
          identificadorPermissao: 'PER_CADASTRO_FORNECEDORES',
        },
        component: () => import(/* webpackChunkName: "cadastros-pessoas" */ '@/views/Cadastros/Pessoas/Fornecedores/FornecedorIndex.vue'),
      },
      {
        path: 'transportadoras',
        name: 'Transportadoras',
        meta: {
          titulo: 'Transportadoras',
          identificadorRecurso: 'CADASTRO_TRANSPORTADORAS',
          identificadorPermissao: 'PER_CADASTRO_TRANSPORTADORAS',
        },
        component: () => import(/* webpackChunkName: "cadastros-pessoas" */ '@/views/Cadastros/Pessoas/Transportadoras/TransportadoraIndex.vue'),
      },
      {
        path: 'representantes',
        name: 'Representantes',
        meta: {
          titulo: 'Representantes',
          identificadorRecurso: 'CADASTRO_REPRESENTANTES',
          identificadorPermissao: 'PER_CADASTRO_REPRESENTANTES',
        },
        component: () => import(/* webpackChunkName: "cadastros-pessoas" */ '@/views/Cadastros/Pessoas/Representantes/RepresentanteIndex.vue'),
      },
      {
        path: 'unidades',
        name: 'Unidades',
        meta: {
          titulo: 'Unidades',
          identificadorRecurso: 'CADASTRO_UNIDADES',
          identificadorPermissao: 'PER_CADASTRO_UNIDADES',
        },
        component: () => import(/* webpackChunkName: "cadastros-unidades" */ '@/views/Cadastros/Produtos/Unidades/UnidadeIndex.vue'),
      },
      {
        path: 'marcas',
        name: 'Marcas',
        meta: {
          titulo: 'Marcas',
          identificadorRecurso: 'CADASTRO_MARCAS',
          identificadorPermissao: 'PER_CADASTRO_MARCAS',
        },
        component: () => import(/* webpackChunkName: "cadastros-marcas" */ '@/views/Cadastros/Produtos/Marcas/MarcaIndex.vue'),
      },
      {
        path: 'ncms',
        name: 'Ncms',
        meta: {
          titulo: 'NCMs',
          identificadorRecurso: 'CADASTRO_NCMS',
          identificadorPermissao: 'PER_CADASTRO_NCMS',
        },
        component: () => import(/* webpackChunkName: "cadastros-ncms" */ '@/views/Cadastros/Produtos/Ncms/NcmIndex.vue'),
      },
      {
        path: 'categorias-produtos',
        name: 'CategoriasProdutos',
        meta: {
          titulo: 'Categorias de Produtos',
          identificadorRecurso: 'CADASTRO_CATEGORIAS_PRODUTOS',
          identificadorPermissao: 'PER_CADASTRO_CATEGORIAS_PRODUTOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-categorias-produtos" */ '@/views/Cadastros/Produtos/CategoriasProdutos/CategoriaProdutoIndex.vue'),
      },
      {
        path: 'caracteristicas',
        name: 'Caracteristicas',
        meta: {
          titulo: 'Características de Produtos',
          identificadorRecurso: 'CADASTRO_CARACTERISTICAS',
          identificadorPermissao: 'PER_CADASTRO_CARACTERISTICAS',
        },
        component: () => import(/* webpackChunkName: "cadastros-caracteristicas" */ '@/views/Cadastros/Produtos/Caracteristicas/CaracteristicaIndex.vue'),
      },
      {
        path: 'produtos',
        name: 'Produtos',
        meta: {
          titulo: 'Produtos',
          identificadorRecurso: 'CADASTRO_PRODUTOS',
          identificadorPermissao: 'PER_CADASTRO_PRODUTOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-produtos" */ '@/views/Cadastros/Produtos/ProdutoIndex.vue'),
      },
      {
        path: 'tabelas-precos',
        name: 'TabelasPrecos',
        meta: {
          titulo: 'Tabela de Preços',
          identificadorRecurso: 'CADASTRO_TABELAS_PRECOS',
          identificadorPermissao: 'PER_CADASTRO_TABELAS_PRECOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-tabelas-precos" */ '@/views/Cadastros/Precificacao/TabelasPreco/TabelaPrecoIndex.vue'),
      },
      {
        path: 'estoques',
        name: 'Estoques',
        meta: {
          titulo: 'Estoques',
          identificadorRecurso: 'CADASTRO_ESTOQUES',
          identificadorPermissao: 'PER_CADASTRO_ESTOQUES',
        },
        component: () => import(/* webpackChunkName: "cadastros-estoques" */ '@/views/Cadastros/Estoques/EstoqueIndex.vue'),
      },
      {
        path: 'servicos',
        name: 'Servicos',
        meta: {
          titulo: 'Serviços',
          identificadorRecurso: 'CADASTRO_SERVICOS',
          identificadorPermissao: 'PER_CADASTRO_SERVICOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-servicos" */ '@/views/Cadastros/PrestacaoServicos/Servicos/ServicoIndex.vue'),
      },
      {
        path: 'centros-custos',
        name: 'CentrosCustos',
        meta: {
          titulo: 'Centros de Custos',
          identificadorRecurso: 'CADASTRO_CENTROS_CUSTOS',
          identificadorPermissao: 'PER_CADASTRO_CENTROS_CUSTOS',
        },
        component: () => import(/* webpackChunkName: "centros-custos" */ '@/views/Cadastros/PlanosContas/CentrosCustos/CentroCustoIndex.vue'),
      },
      {
        path: 'bancos',
        name: 'Bancos',
        meta: {
          titulo: 'Bancos',
          identificadorRecurso: 'CADASTRO_BANCOS',
          identificadorPermissao: 'PER_CADASTRO_BANCOS',
        },
        component: () => import(/* webpackChunkName: "bancos" */ '@/views/Cadastros/Financeiro/Bancos/BancoIndex.vue'),
      },
      {
        path: 'tipos-documento-financeiro',
        name: 'TiposDocumentoFinanceiro',
        meta: {
          titulo: 'Tipos de Documento',
          identificadorRecurso: 'CADASTRO_TIPOS_DOCUMENTO_FINANCEIRO',
          identificadorPermissao: 'PER_CADASTRO_TIPOS_DOCUMENTO_FINANCEIRO',
        },
        component: () => import(/* webpackChunkName: "tipos-documento-financeiro" */ '@/views/Cadastros/Financeiro/TiposDocumentos/TipoDocumentoFinanceiroIndex.vue'),
      },
      {
        path: 'planos-contas',
        name: 'PlanosContas',
        meta: {
          titulo: 'Planos de Contas',
          identificadorRecurso: 'CADASTRO_PLANOS_CONTAS',
          identificadorPermissao: 'PER_CADASTRO_PLANOS_CONTAS',
        },
        component: () => import(/* webpackChunkName: "planos-contas" */ '@/views/Cadastros/PlanosContas/PlanoContaIndex.vue'),
      },
      {
        path: 'contas-contabeis-referenciadas',
        name: 'ContasContabeisReferenciadas',
        meta: {
          titulo: 'Contas Contábeis Referenciadas',
          identificadorRecurso: 'CADASTRO_CONTAS_CONTABEIS_REFERENCIADAS',
          identificadorPermissao: 'PER_CADASTRO_CONTAS_CONTABEIS_REFERENCIADAS',
        },
        component: () => import(/* webpackChunkName: "contas-contabeis-referenciadas" */ '@/views/Cadastros/PlanosContas/ContasContabeisReferenciadas/ContaContabilReferenciadaIndex.vue'),
      },
      {
        path: 'contas',
        name: 'Contas',
        meta: {
          titulo: 'Contas',
          identificadorRecurso: 'CADASTRO_CONTAS',
          identificadorPermissao: 'PER_CADASTRO_CONTAS',
        },
        component: () => import(/* webpackChunkName: "contas" */ '@/views/Cadastros/Financeiro/Contas/ContaIndex.vue'),
      },
      {
        path: 'caixas',
        name: 'Caixas',
        meta: {
          titulo: 'Caixas',
          identificadorRecurso: 'CADASTRO_CAIXAS',
          identificadorPermissao: 'PER_CADASTRO_CAIXAS',
        },
        component: () => import(/* webpackChunkName: "contas" */ '@/views/Cadastros/Financeiro/Caixas/CaixaIndex.vue'),
      },
      {
        path: 'cfops',
        name: 'CFOPs',
        meta: {
          titulo: 'CFOPs',
          identificadorRecurso: 'CADASTRO_CFOPS',
          identificadorPermissao: 'PER_CADASTRO_CFOPS',
        },
        component: () => import(/* webpackChunkName: "tributacoes" */ '@/views/Cadastros/Tributacoes/Cfops/CfopIndex.vue'),
      },
      {
        path: 'cenarios-fiscais',
        name: 'CenariosFiscais',
        meta: {
          titulo: 'Cenários Fiscais',
          identificadorRecurso: 'CADASTRO_CENARIO_FISCAL',
          identificadorPermissao: 'PER_CADASTRO_CENARIO_FISCAL',
        },
        component: () => import(/* webpackChunkName: "tributacoes" */ '@/views/Cadastros/Tributacoes/CenariosFiscais/CenarioFiscalIndex.vue'),
      },
      {
        path: 'formas-pagamento',
        name: 'FormasPagamento',
        meta: {
          titulo: 'Formas de Pagamento',
          identificadorRecurso: 'CADASTRO_FORMAS_PAGAMENTO',
          identificadorPermissao: 'PER_CADASTRO_FORMAS_PAGAMENTO',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Cadastros/Financeiro/FormasPagamento/FormaPagamentoIndex.vue'),
      },
      {
        path: 'processos-vendas',
        name: 'ProcessosVendas',
        meta: {
          titulo: 'Processos de Vendas',
          identificadorRecurso: 'CADASTRO_PROCESSOS_VENDAS',
          identificadorPermissao: 'PER_CADASTRO_PROCESSOS_VENDAS',
        },
        component: () => import(/* webpackChunkName: "vendas" */ '@/views/Cadastros/Vendas/ProcessosVendas/ProcessoVendasIndex.vue'),
      },
      {
        path: 'equipes-alcada-despesas',
        name: 'EquipesAlcadaDespesas',
        meta: {
          titulo: 'Equipes de Alçada de Despesas',
          identificadorRecurso: 'CADASTRO_EQUIPES_ALCADA_DESPESAS',
          identificadorPermissao: 'PER_CADASTRO_EQUIPES_ALCADA_DESPESAS',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Cadastros/Financeiro/EquipesAlcadaDespesas/EquipeAlcadaDespesasIndex.vue'),
      },
    ],
  },
];

export default rotasModuloCadastros;
