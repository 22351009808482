export default class UtilitarioGeral {
  static valorValido(valor: any):boolean {
    if (valor === undefined || valor === null || valor === '') {
      return false;
    }
    return true;
  }

  static validaValorComLimiteCaracteres(valor: any, limiteCaracteres: number):boolean {
    if (!this.valorValido(valor)) {
      return false;
    }
    if (valor.length === limiteCaracteres) {
      return true;
    }
    return false;
  }

  static objetoValido(objeto: any):boolean {
    if (objeto === undefined || objeto === null || objeto === '') {
      return false;
    }

    if (objeto) {
      return true;
    }

    return false;
  }

  static validaBoleano(valor: any):boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    return true;
  }

  static validaNumerico(valor: any):boolean {
    if (valor === undefined || valor === null || Number.isNaN(valor)) {
      return false;
    }

    const valorNumerico = Number.parseFloat(valor);
    if (valorNumerico <= 0 || valorNumerico >= 0) { return true; }

    return false;
  }

  static validaCodigo(valor: any):boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    if (valor > 0) { return true; }

    return false;
  }

  /**
 * Cria instância local para o objeto com os mesmos valores
 * porém removendo as referências e impactos visuais na tela
 */
  static instanciaObjetoLocal(objeto: any):any {
    return JSON.parse(JSON.stringify(objeto));
  }

  static validaLista(valor: any):boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    if (valor.length > 0) {
      return true;
    }
    return false;
  }

  static converterValorNumericoJson(valor: any):number {
    if (this.valorValido(valor)) {
      const valorConvertido = valor.replace('.', '').replace(',', '.');
      return valorConvertido;
    }
    return 0;
  }

  static montaExibicaoEmpresa(codigoExibicao: string, nomeExibicao: string):string {
    let exibicaoEmpresa = '';
    if (this.valorValido(codigoExibicao)) {
      exibicaoEmpresa += `${codigoExibicao} - `;
    }
    exibicaoEmpresa += nomeExibicao;
    return exibicaoEmpresa;
  }

  static primeiraLetraMaiuscula(texto: string):string {
    if (this.valorValido(texto)) {
      if (texto.length > 1) {
        return texto && texto[0].toUpperCase() + texto.slice(1).toLowerCase();
      }
      return texto.toUpperCase();
    }
    return '';
  }

  static documentoIdentificacaoCPFouCNPJ(cpfOuCnpj: string):string {
    let texto = '';
    if (cpfOuCnpj === undefined || cpfOuCnpj === null || cpfOuCnpj === '') {
      return '';
    }

    if (cpfOuCnpj.length === 11) {
      texto = 'CPF';
    } if (cpfOuCnpj.length === 14) {
      texto = 'CNPJ';
    }

    return texto;
  }

  static obterApenasNumeros(valor: string):string {
    const valorNovo = valor.replace(/[^0-9]/g, '');
    return valorNovo;
  }

  static clonarObjeto(objeto: any): any {
    return JSON.parse(JSON.stringify(objeto));
  }

  static gerarTokenUnico(tamanho = 30):string {
    let token = '';
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const quantidadeCaracteres = caracteres.length;
    for (let i = 0; i < tamanho; i += 1) {
      token += caracteres.charAt(Math.floor(Math.random() * quantidadeCaracteres));
    }
    return token;
  }

  static obterCorTexto(texto: string):string {
    const cores = [
      'red', 'blue', 'green', 'purple', 'orange',
    ];
    const letra = texto.charAt(0).toLowerCase();
    const index = letra.charCodeAt(0) - 97;
    return cores[index % cores.length];
  }

  static sleep(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static validarEmail(valor: string): boolean {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(valor);
  }

  static limitarQuantidadeMaximaCaracteres(valor:string, quantidadeCaracteres: number): string {
    let novoValor = '';
    if (this.valorValido(valor)) {
      if (valor.length > quantidadeCaracteres) {
        novoValor = valor.slice(0, quantidadeCaracteres);
      } else {
        return valor;
      }
    }

    return novoValor;
  }

  static downloadArquivo(linkUrl: string): void {
    // Cria um elemento <a> temporário para realizar o download
    const link = document.createElement('a');
    link.href = linkUrl;
    link.download = this.obterNomeArquivo(linkUrl); // Opcional: Define um nome de arquivo
    document.body.appendChild(link); // Adiciona o link ao corpo do documento para ser clicável
    link.click(); // Simula um clique no link para iniciar o download
    document.body.removeChild(link); // Remove o link do corpo do documento após o download
  }

  static obterNomeArquivo(url: string): string {
    // Obter o nome do arquivo a partir do URL
    return url.split('/').pop() || 'arquivo';
  }

  static extrairNumeros(texto: string) : string {
    if (texto === undefined || texto === null || texto === '') {
      return '';
    }

    const regex = /\d+/g;
    const numerosEncontrados = texto.match(regex);

    if (numerosEncontrados) {
      return numerosEncontrados.join('');
    }
    return '';
  }

  static removerTagsHtml(texto:string):string {
    if (!texto) {
      return texto;
    }

    let valorTratado = texto;

    // Remove o conteúdo das tags <script> e <style>
    const patternScriptStyle = /<script[^>]*>[\s\S]*?<\/script>|<style[^>]*>[\s\S]*?<\/style>/gi;
    valorTratado = valorTratado.replace(patternScriptStyle, '');

    // Remove todas as tags HTML
    const patternTags = /<.*?>/g;
    valorTratado = valorTratado.replace(patternTags, '');

    // Substitui múltiplos espaços em branco por um único espaço
    valorTratado = valorTratado.replace(/\s+/g, ' ');

    // Remove espaços em branco no início e no fim da string
    valorTratado = valorTratado.trim();

    return valorTratado;
  }

  static gerarIdentificadorTexto(texto:string):string {
    if (!texto) {
      return texto;
    }

    let valorTratado = texto;

    // Converte o texto para minúsculas
    valorTratado = valorTratado.toLowerCase();

    // Substitui caracteres acentuados por suas versões não acentuadas
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç';
    const to = 'aaaaaeeeeeiiiiooooouuuunc';
    for (let i = 0, l = from.length; i < l; i += 1) {
      valorTratado = valorTratado.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove caracteres especiais
    valorTratado = valorTratado.replace(/[^a-z0-9\s-]/g, '');

    // Substitui espaços e traços múltiplos por um único traço
    valorTratado = valorTratado.replace(/[\s-]+/g, '-');

    // Remove traços no início e no final
    valorTratado = valorTratado.replace(/^-+|-+$/g, '');

    return valorTratado;
  }
}
