import { RouteRecordRaw } from 'vue-router';

const rotasTelaInicial: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'TelaInicial',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: '',
        name: 'MinhaDashboard',
        meta: {
          titulo: 'Minha Dashboard',
          identificadorRecurso: 'MINHA_DASHBOARD',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/MinhaDashboard.vue'),
      },
      {
        path: 'faturamentos',
        name: 'CentralFaturamentos',
        meta: {
          titulo: 'Central de Faturamentos',
          identificadorRecurso: 'VENDAS',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "faturamentos" */ '@/views/Vendas/Faturamento/FaturamentoVendasIndex.vue'),
      },
    ],
  },
];

export default rotasTelaInicial;
