import { RouteRecordRaw } from 'vue-router';

const rotasModuloMeuSistema: Array<RouteRecordRaw> = [
  {
    path: '/meu-sistema',
    name: 'MeuSistema',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'agenda',
        name: 'Agenda',
        meta: {
          titulo: 'Agenda',
          identificadorRecurso: 'AGENDA',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Agendamentos/AgendamentoIndex.vue'),
      },
      {
        path: 'agenda/detalhe/:codigoAgendamento',
        name: 'Agendamento',
        meta: {
          titulo: 'Agenda',
          identificadorRecurso: 'AGENDAMENTO',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Agendamentos/AgendamentoIndex.vue'),
      },
      {
        path: 'notificacoes',
        name: 'Notificacoes',
        meta: {
          titulo: 'Notificações',
          identificadorRecurso: 'NOTIFICACOES',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Notificacoes/NotificacoesIndex.vue'),
      },
      {
        path: 'empresas',
        name: 'Empresas',
        meta: {
          titulo: 'Empresas',
          identificadorRecurso: 'PAINEL_EMPRESAS',
          identificadorPermissao: 'AUT_PAINEL_EMPRESAS',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Empresas/EmpresaIndex.vue'),
      },
      {
        path: 'multi-empresas/cadastros',
        name: 'MultiEmpresasCadastros',
        meta: {
          titulo: 'Multi Empresas - Cadastros',
          identificadorRecurso: 'PAINEL_MULTI_EMPRESAS_CADASTROS',
          identificadorPermissao: 'AUT_PAINEL_MULTI_EMPRESAS_CADASTROS',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Empresas/MultiEmpresasCadastrosIndex.vue'),
      },
      {
        path: 'controle-numeracoes',
        name: 'ControleNumeracoes',
        meta: {
          titulo: 'Controle de Numerações',
          identificadorRecurso: 'CONTROLE_PERMISSOES_USUARIO',
          identificadorPermissao: 'AUT_CONTROLE_PERMISSOES_USUARIO',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/ControleNumeracoes/ControleNumeracoesIndex.vue'),
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        meta: {
          titulo: 'Usuários',
          identificadorRecurso: 'CADASTRO_USUARIOS',
          identificadorPermissao: 'PER_CADASTRO_USUARIOS',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Usuarios/UsuarioIndex.vue'),
      },
      {
        path: 'perfis-usuarios',
        name: 'PerfisUsuarios',
        meta: {
          titulo: 'Perfis de Usuários',
          identificadorRecurso: 'CADASTRO_PERFIS_USUARIOS',
          identificadorPermissao: 'PER_CADASTRO_PERFIS_USUARIOS',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Usuarios/PerfilUsuarioIndex.vue'),
      },
      {
        path: 'usuarios/controle-permissoes',
        name: 'ControlePermissoesUsuario',
        meta: {
          titulo: 'Controle de Permissões',
          identificadorRecurso: 'CONTROLE_PERMISSOES_USUARIO',
          identificadorPermissao: 'AUT_CONTROLE_PERMISSOES_USUARIO',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Usuarios/ControlePermissoesIndex.vue'),
      },
      {
        path: 'controle-autorizacoes',
        name: 'ControleAutorizacoes',
        meta: {
          titulo: 'Controle de Autorizações',
          identificadorRecurso: 'CONTROLE_AUTORIZACOES',
          identificadorPermissao: 'AUT_CONTROLE_AUTORIZACOES',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/ControleAutorizacoes/ControleAutorizacoesIndex.vue'),
      },
      {
        path: 'tipos-custos',
        name: 'TiposCustos',
        meta: {
          titulo: 'Tipos de Custos',
          identificadorRecurso: 'CADASTRO_TIPOS_CUSTO',
          identificadorPermissao: 'PER_CADASTRO_TIPOS_CUSTO',
        },
        component: () => import(/* webpackChunkName: "meu-sistema-precificacao" */ '@/views/Cadastros/Precificacao/TipoCustos/TipoCustoIndex.vue'),
      },
      {
        path: 'tipos-precos',
        name: 'TiposPrecos',
        meta: {
          titulo: 'Tipos de Preços',
          identificadorRecurso: 'CADASTRO_TIPOS_PRECO',
          identificadorPermissao: 'PER_CADASTRO_TIPOS_PRECO',
        },
        component: () => import(/* webpackChunkName: "meu-sistema-precificacao" */ '@/views/Cadastros/Precificacao/TipoPrecos/TipoPrecoIndex.vue'),
      },
      {
        path: 'personalizacoes-telas',
        name: 'PersonalizacoesTelas',
        meta: {
          titulo: 'Personalizações de Telas',
          identificadorRecurso: 'PERSONALIZACOES_TELAS',
          identificadorPermissao: 'AUT_PERSONALIZACOES_TELAS',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/PersonalizacoesTelas/PersonalizacaoTelaIndex.vue'),
      },
      {
        path: 'controle-parametros',
        name: 'ControleParametros',
        meta: {
          titulo: 'Controle de Parâmetros',
          identificadorRecurso: 'CONTROLE_PARAMETROS',
          identificadorPermissao: 'AUT_CONTROLE_PARAMETROS',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Parametros/ParametrosIndex.vue'),
      },
      {
        path: 'servidores-email-notificacao',
        name: 'ServidorEmailNotificacao',
        meta: {
          titulo: 'Servidor E-mail Notificação',
          identificadorRecurso: 'CADASTRO_SERVIDORES_EMAIL_NOTIFICACAO',
          identificadorPermissao: 'PER_CADASTRO_SERVIDORES_EMAIL_NOTIFICACAO',
        },
        component: () => import(/* webpackChunkName: "meu-sistema" */ '@/views/MeuSistema/Notificacoes/ServidoresEmailNotificacao/ServidorEmailNotificacaoIndex.vue'),
      },
    ],
  },
];

export default rotasModuloMeuSistema;
