import { RouteRecordRaw } from 'vue-router';

const rotasModuloFinanceiro: Array<RouteRecordRaw> = [
  {
    path: '/financeiro',
    name: 'Financeiro',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'contas-pagar',
        name: 'ContasPagar',
        meta: {
          titulo: 'Contas a Pagar',
          identificadorRecurso: 'CONTAS_PAGAR',
          identificadorPermissao: 'PER_CONTAS_PAGAR',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Financeiro/ContasPagar/ContaPagarIndex.vue'),
      },
      {
        path: 'contas-receber',
        name: 'ContasReceber',
        meta: {
          titulo: 'Contas a Receber',
          identificadorRecurso: 'CONTAS_RECEBER',
          identificadorPermissao: 'PER_CONTAS_RECEBER',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Financeiro/ContasReceber/ContaReceberIndex.vue'),
      },
      {
        path: 'movimentacoes-contas',
        name: 'MovimentacoesContas',
        meta: {
          titulo: 'Movimentações de Contas',
          identificadorRecurso: 'MOVIMENTACOES_CONTAS',
          identificadorPermissao: 'PER_MOVIMENTACOES_CONTAS',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroIndex.vue'),
      },
      {
        path: 'controle-caixas',
        name: 'ControleCaixas',
        meta: {
          titulo: 'Controle de Caixas',
          identificadorRecurso: 'CONTROLE_CAIXAS',
          identificadorPermissao: 'AUT_CONTROLE_CAIXAS',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Financeiro/ControleCaixas/ControleCaixasIndex.vue'),
      },
      {
        path: 'baixa-contas-pagar',
        name: 'BaixaContasPagar',
        meta: {
          titulo: 'Baixa de Contas a Pagar',
          identificadorRecurso: 'BAIXAS_CONTAS_PAGAR',
          identificadorPermissao: 'AUT_BAIXAS_CONTAS_PAGAR',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Financeiro/BaixaContasPagar/BaixaContasPagarIndex.vue'),
      },
      {
        path: 'baixa-contas-receber',
        name: 'BaixaContasReceber',
        meta: {
          titulo: 'Baixa de Contas a Receber',
          identificadorRecurso: 'BAIXAS_CONTAS_RECEBER',
          identificadorPermissao: 'AUT_BAIXAS_CONTAS_RECEBER',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Financeiro/BaixaContasReceber/BaixaContasReceberIndex.vue'),
      },
      {
        path: 'relatorios/contas-pagar-receber',
        name: 'RelatorioContasPagarReceber',
        meta: {
          titulo: 'Relatório de Contas a Pagar/Receber',
          identificadorRecurso: 'RELATORIO_CONTAS_PAGAR_RECEBER',
          identificadorPermissao: 'REL_CONTAS_PAGAR_RECEBER',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Relatorios/Financeiro/TitulosFinanceiros/RelatorioTitulosFinanceirosIndex.vue'),
      },
      {
        path: 'relatorios/balancete',
        name: 'RelatorioBalancete',
        meta: {
          titulo: 'Relatório Balancete',
          identificadorRecurso: 'RELATORIO_BALANCETE',
          identificadorPermissao: 'REL_BALANCETE',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Relatorios/Financeiro/Balancete/RelatorioBalanceteIndex.vue'),
      },
      {
        path: 'relatorios/fluxo-caixa',
        name: 'RelatorioFluxoCaixa',
        meta: {
          titulo: 'Relatório de Fluxo de Caixa',
          identificadorRecurso: 'RELATORIO_FLUXO_CAIXA',
          identificadorPermissao: 'REL_FLUXO_CAIXA',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Relatorios/Financeiro/FluxoCaixa/RelatorioFluxoCaixaIndex.vue'),
      },
      {
        path: 'relatorios/despesas-centro-custo',
        name: 'RelatorioDespesasCentroCusto',
        meta: {
          titulo: 'Relatório de Despesas por Centro de Custo',
          identificadorRecurso: 'RELATORIO_DESPESAS_CENTRO_CUSTO',
          identificadorPermissao: 'REL_DESPESAS_CENTRO_CUSTO',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Relatorios/Financeiro/DespesasCentroCusto/RelatorioDespesasCentroCustoIndex.vue'),
      },
      {
        path: 'relatorios/projecao-financeira',
        name: 'RelatorioProjecaoFinanceira',
        meta: {
          titulo: 'Relatório de Projeção Financeira',
          identificadorRecurso: 'RELATORIO_PROJECAO_FINANCEIRA',
          identificadorPermissao: 'REL_PROJECAO_FINANCEIRA',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Relatorios/Financeiro/ProjecaoFinanceira/RelatorioProjecaoFinanceiraIndex.vue'),
      },
      {
        path: 'relatorios/rentabilidade-empresa',
        name: 'RelatorioRentabilidadeEmpresa',
        meta: {
          titulo: 'Relatório de Rentabilidade da Empresa',
          identificadorRecurso: 'RELATORIO_RENTABILIDADE_EMPRESA',
          identificadorPermissao: 'REL_RENTABILIDADE_EMPRESA',
        },
        component: () => import(/* webpackChunkName: "financeiro" */ '@/views/Relatorios/Financeiro/RentabilidadeEmpresa/RelatorioRentabilidadeEmpresaIndex.vue'),
      },
    ],
  },
];

export default rotasModuloFinanceiro;
