const obterItem = (chave:string):unknown => {
  const data: any = typeof window !== 'undefined' ? localStorage.getItem(chave) : '';

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const salvarItem = (chave: string, valor:unknown):void => {
  const stringify: string = typeof valor !== 'string' ? JSON.stringify(valor) : valor;
  return localStorage.setItem(chave, stringify);
};

const removerItem = (chave:string):void => {
  localStorage.removeItem(chave);
};

export { obterItem, salvarItem, removerItem };
