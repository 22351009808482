import { RouteRecordRaw } from 'vue-router';

const rotasModuloCompras: Array<RouteRecordRaw> = [
  {
    path: '/compras',
    name: 'Compras',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'recebimentos-mercadorias',
        name: 'RecebimentosMercadorias',
        meta: {
          titulo: 'Recebimentos de Mercadorias',
          identificadorRecurso: 'RECEBIMENTOS_MERCADORIAS',
          identificadorPermissao: 'PER_RECEBIMENTOS_MERCADORIAS',
        },
        component: () => import(/* webpackChunkName: "compras" */ '@/views/Compras/RecebimentoMercadorias/RecebimentoMercadoriasIndex.vue'),
      },
      {
        path: 'devolucoes-compras',
        name: 'DevolucoesCompras',
        meta: {
          titulo: 'Devoluções de Compras',
          identificadorRecurso: 'DEVOLUCOES_COMPRAS',
          identificadorPermissao: 'PER_DEVOLUCOES_COMPRAS',
        },
        component: () => import(/* webpackChunkName: "compras" */ '@/views/Compras/DevolucoesCompras/DevolucaoComprasIndex.vue'),
      },
    ],
  },
];

export default rotasModuloCompras;
