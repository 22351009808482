
import { defineComponent } from 'vue';
import ptBR from 'ant-design-vue/lib/locale-provider/pt_BR';
import dayjs from 'dayjs';

dayjs.locale('pt-br');

export default defineComponent({
  setup() {
    return {
      ptBR,
    };
  },
});
