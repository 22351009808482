import { reactive, readonly } from 'vue';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import { IDTODadosUsuarioAutenticado } from '@/models/DTO/MeuSistema/Usuarios/IDTODadosUsuarioAutenticado';
import { IConfiguracaoApp } from '@/core/models/IConfiguracaoApp';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOMeusAtalhos } from '@/models/DTO/MeuSistema/IDTOMeusAtalhos';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IMenuMeusAtalhos } from '@/core/models/IMenuMeusAtalhos';
import { IMenu } from '@/models/Entidades/MeuSistema/IMenu';
import { obterItem, salvarItem } from '@/core/gerentes/GerenteLocalStorage';

const state = reactive({
  configuracaoApp: {} as IConfiguracaoApp,
  dadosUsuarioAutenticado: {} as IDTODadosUsuarioAutenticado,
  quantidadeNotificacoesNaoLidas: 0,
  menus: [] as IMenu[],
  meusAtalhos: [] as IDTOMeusAtalhos[],
  empresaEmOperacao: {} as IDTODadosEmpresa,
  empresasDisponiveis: [] as IDTODadosEmpresa[],
  multiEmpresas: false,
  carregando: false,
  larguraTela: window.innerWidth,
  layoutMobile: false,
  menuCollapse: false,
});

const getters = {
  multiEmpresas():boolean {
    return state.multiEmpresas;
  },
  quantidadeNotificacoesNaoLidas():number {
    return state.quantidadeNotificacoesNaoLidas;
  },
  codigoEmpresaOperacao():number {
    return state.empresaEmOperacao.codigoEmpresa;
  },
  codigoUsuarioAutenticado():number {
    return state.dadosUsuarioAutenticado.codigo;
  },
  nomeUsuarioAutenticado():string {
    return state.dadosUsuarioAutenticado.nome;
  },
  imagemUsuarioAutenticado():string {
    return state.dadosUsuarioAutenticado.imagem;
  },
  emailUsuarioAutenticado():string {
    return state.dadosUsuarioAutenticado.email;
  },
  empresasUsuarioAutenticado():number[] {
    return state.dadosUsuarioAutenticado.empresas;
  },
  dadosIdentificacaoEmpresa():string {
    return UtilitarioGeral.montaExibicaoEmpresa(state.empresaEmOperacao.codigoExibicao, state.empresaEmOperacao.nomeExibicao);
  },
  cpfCnpjFormatado():string {
    return UtilitarioMascara.mascararCPFOuCNPJ(state.empresaEmOperacao.cpfCnpj);
  },
  dadosEmpresasAutorizadasUsuario():IDTODadosEmpresa[] {
    const listaDadosEmpresas:IDTODadosEmpresa[] = [] as IDTODadosEmpresa[];
    state.dadosUsuarioAutenticado.empresas.forEach((codigoEmpresa) => {
      const dadosEmpresa = state.empresasDisponiveis.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (dadosEmpresa !== undefined) {
        listaDadosEmpresas.push(dadosEmpresa);
      }
    });
    return listaDadosEmpresas;
  },
  atalhosEmpresaEmOperacao():IMenuMeusAtalhos[] {
    const menuAtalhos:IMenuMeusAtalhos[] = [];

    if (UtilitarioGeral.validaLista(state.meusAtalhos)) {
      const atalhosEmOperacao = state.meusAtalhos.filter((atalho) => atalho.codigoEmpresa === state.empresaEmOperacao.codigoEmpresa);
      let categorias = atalhosEmOperacao.map((a) => a.categoria);
      categorias = categorias.filter((c, index) => categorias.indexOf(c) === index);
      let categoriaAtual = '';

      categorias.forEach((categoria:string) => {
        if (categoriaAtual !== categoria) {
          const menuAtalhoItem:IMenuMeusAtalhos = {} as IMenuMeusAtalhos;
          menuAtalhoItem.categoria = categoria;
          menuAtalhoItem.atalhos = atalhosEmOperacao.filter((c) => c.categoria === categoria);
          menuAtalhos.push(menuAtalhoItem);
          categoriaAtual = categoria;
        }
      });
    }

    return menuAtalhos;
  },
  atalhoAdicionado(identificadorRecurso: string, empresas: number[]):boolean {
    let existeAtalho = false;
    if (UtilitarioGeral.validaLista(state.meusAtalhos)) {
      const atalhosFiltrados = state.meusAtalhos.filter((atalho) => atalho.identificadorRecurso === identificadorRecurso);
      if (UtilitarioGeral.validaLista(atalhosFiltrados)) {
        empresas.forEach((codigoEmpresa) => {
          const atalhosComEmpresas = atalhosFiltrados.filter((atalho) => atalho.codigoEmpresa === codigoEmpresa);
          if (UtilitarioGeral.validaLista(atalhosComEmpresas)) {
            existeAtalho = true;
          }
        });
      }
    }
    return existeAtalho;
  },
  menuCollapse(): boolean {
    return state.menuCollapse;
  },
};

const mutations = {
  atualizarCarregando(valor:boolean):void {
    state.carregando = valor;
  },
  atualizarNotificacaoNaoLida(quantidadeNotificacoesNaoLidas:number):void {
    state.quantidadeNotificacoesNaoLidas = quantidadeNotificacoesNaoLidas;
  },
  adicionarNotificacaoNaoLida():void {
    state.quantidadeNotificacoesNaoLidas += 1;
  },
  diminuirNotificacaoNaoLida():void {
    state.quantidadeNotificacoesNaoLidas -= 1;
  },
  atualizarConfiguracaoApp(configuracaoApp: IConfiguracaoApp):void {
    state.configuracaoApp = configuracaoApp;
  },
  atualizarUrlApiESignalR(url: string):void {
    state.configuracaoApp.urlApi = url;
    state.configuracaoApp.urlHubSignalR = url.replace('v1', 'hub-comunicacao');
  },
  async atualizarDadosEmpresas(empresasDisponiveis: IDTODadosEmpresa[]):Promise<void> {
    state.multiEmpresas = false;
    state.empresasDisponiveis = empresasDisponiveis;
    if (UtilitarioGeral.validaLista(state.empresasDisponiveis)) {
      let empresaOperacao:IDTODadosEmpresa = state.empresasDisponiveis[0];
      const codigoEmpresaOperacao = obterItem('SS-NEOX-EMPRESA-OPERACAO');
      if (UtilitarioGeral.valorValido(codigoEmpresaOperacao)) {
        const indexEmpresa = state.empresasDisponiveis.findIndex((c) => c.codigoEmpresa === Number(codigoEmpresaOperacao));
        if (indexEmpresa >= 0) {
          empresaOperacao = state.empresasDisponiveis[indexEmpresa];
        }
      }
      await this.definirEmpresaEmOperacao(empresaOperacao);
      state.multiEmpresas = (state.empresasDisponiveis.length > 1);
    }
  },
  async definirEmpresaEmOperacao(empresa: IDTODadosEmpresa):Promise<void> {
    state.empresaEmOperacao.codigoEmpresa = empresa.codigoEmpresa;
    state.empresaEmOperacao.codigoExibicao = empresa.codigoExibicao;
    state.empresaEmOperacao.nomeExibicao = empresa.nomeExibicao;
    state.empresaEmOperacao.cpfCnpj = empresa.cpfCnpj;
    state.empresaEmOperacao.logo = empresa.logo;
    state.empresaEmOperacao.statusAssinatura = empresa.statusAssinatura;
    state.empresaEmOperacao.dataVencimentoAssinatura = empresa.dataVencimentoAssinatura;
    salvarItem('SS-NEOX-EMPRESA-OPERACAO', empresa.codigoEmpresa.toString());
  },
  atualizarDadosUsuarioAutenticado(dadosUsuarioAutenticado: IDTODadosUsuarioAutenticado):void {
    state.dadosUsuarioAutenticado = dadosUsuarioAutenticado;
  },
  atualizarImagemUsuarioAutenticado(imagem: string):void {
    state.dadosUsuarioAutenticado.imagem = imagem;
  },
  atualizarMeusAtalhos(meusAtalhos: IDTOMeusAtalhos[]):void {
    state.meusAtalhos = meusAtalhos;
  },
  atualizarMenus(menus: IMenu[]):void {
    state.menus = menus;
  },
  atualizarNomeEmpresa(nomeEmpresa: string):void {
    state.empresaEmOperacao.nomeExibicao = nomeEmpresa;
  },
  atualizarLarguraTela():void {
    state.larguraTela = window.innerWidth;
    state.layoutMobile = (state.larguraTela < 900);
  },
  atualizarMenuCollapse():void {
    state.menuCollapse = !state.menuCollapse;
  },
};

const actions = {
};

export default {
  state: readonly(state),
  getters,
  mutations,
  actions,
};
