import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import rotasModuloMeuSistema from '@/router/rotasModuloMeuSistema';
import rotasModuloPainelAdministrativo from '@/router/rotasModuloPainelAdministrativo';
import rotasModuloCadastros from '@/router/rotasModuloCadastros';
import rotasModuloEstoques from '@/router/rotasModuloEstoques';
import rotasAutenticacao from './rotasAutenticacao';
import rotasModuloFinanceiro from './rotasModuloFinanceiro';
import rotasModuloCompras from './rotasModuloCompras';
import rotasModuloVendas from './rotasModuloVendas';
import rotasTelaInicial from './rotasTelaInicial';
import rotasModuloFiscal from '@/router/rotasModuloFiscal';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'TelaInicial',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasTelaInicial,
  },
  {
    path: '/',
    name: 'ModuloMeuSistema',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloMeuSistema,
  },
  {
    path: '/',
    name: 'ModuloPainelAdministrativo',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloPainelAdministrativo,
  },
  {
    path: '/',
    name: 'ModuloCadastros',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloCadastros,
  },
  {
    path: '/',
    name: 'ModuloEstoques',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloEstoques,
  },
  {
    path: '/',
    name: 'ModuloCompras',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloCompras,
  },
  {
    path: '/',
    name: 'ModuloVendas',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloVendas,
  },
  {
    path: '/',
    name: 'ModuloFinanceiro',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloFinanceiro,
  },
  {
    path: '/',
    name: 'ModuloFiscal',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/ERPLayout.vue'),
    children: rotasModuloFiscal,
  },
  {
    path: '/',
    name: 'Autenticacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/AutenticacaoLayout.vue'),
    children: rotasAutenticacao,
  },
  {
    path: '',
    name: 'IdentificarAmbiente',
    meta: {
      titulo: 'Bem vindo',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/IdentificarAmbiente.vue'),
  },
  {
    path: '/ambiente-nao-encontrado',
    name: 'AmbienteNaoEncontrado',
    meta: {
      titulo: 'Ambiente não encontrado!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/AmbienteNaoEncontrado.vue'),
  },
  {
    path: '/assinatura-inativa',
    name: 'AssinaturaInativa',
    meta: {
      titulo: 'Assinatura Inativa',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/AssinaturaInativa.vue'),
  },
  {
    path: '/ambiente-nao-autenticado',
    name: 'AmbienteNaoAutenticado',
    meta: {
      titulo: 'Ambiente não autenticado!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/AmbienteNaoAutenticado.vue'),
  },
  {
    path: '/contratacao',
    name: 'Contratacao',
    meta: {
      titulo: 'Vamos começar',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Contratacao.vue'),
  },
  {
    path: '/sem-permissao',
    name: 'SemPermissao',
    meta: {
      titulo: 'Você não possui permissão para realizar esta operação!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/SemPermissao.vue'),
  },
  {
    path: '/pagina-nao-encontrada',
    name: 'PaginaNaoEncontrada',
    meta: {
      titulo: 'Página não encontrada',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/PaginaNaoEncontrada.vue'),
  },
  {
    path: '/erro-requisicao',
    name: 'ErroRequisicao',
    meta: {
      titulo: 'Erro de requisição',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/ErroRequisicao.vue'),
  },
  {
    path: '/configuracao-inicial',
    name: 'ConfiguracaoInicial',
    meta: {
      titulo: 'Configuração Inicial',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/views/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialIndex.vue'),
  },
  {
    path: '/esqueci-minha-senha',
    name: 'EsqueciMinhaSenha',
    meta: {
      titulo: 'Esqueci Minha Senha',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/EsqueciMinhaSenha.vue'),
  },
  {
    path: '/redefinir-senha/:contratante/:codigoAcesso',
    name: 'RedefinirSenha',
    meta: {
      titulo: 'Redefinir Senha',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/RedefinirSenha.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `NeoX » ${to.meta.titulo}`;
  next();
});

export default router;
