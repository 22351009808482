import { RouteRecordRaw } from 'vue-router';

const rotasModuloPainelAdministrativo: Array<RouteRecordRaw> = [
  {
    path: '/painel-administrativo',
    name: 'PainelAdministrativo',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'categorias-permissoes',
        name: 'CategoriasPermissoes',
        meta: {
          titulo: 'Categorias de Permissões',
          identificadorRecurso: 'CADASTRO_CATEGORIAS_PERMISSAO',
          identificadorPermissao: 'PER_CADASTRO_CATEGORIAS_PERMISSAO',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/Permissoes/Categorias/CategoriaPermissaoIndex.vue'),
      },
      {
        path: 'permissoes',
        name: 'Permissoes',
        meta: {
          titulo: 'Permissões',
          identificadorRecurso: 'CADASTRO_PERMISSOES',
          identificadorPermissao: 'PER_CADASTRO_PERMISSOES',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/Permissoes/PermissaoIndex.vue'),
      },
      {
        path: 'modulos',
        name: 'Modulos',
        meta: {
          titulo: 'Módulos',
          identificadorRecurso: 'CADASTRO_MODULOS',
          identificadorPermissao: 'PER_CADASTRO_MODULOS',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/Modulos/ModuloIndex.vue'),
      },
      {
        path: 'ambientes',
        name: 'Ambientes',
        meta: {
          titulo: 'Ambientes',
          identificadorRecurso: 'CADASTRO_AMBIENTES',
          identificadorPermissao: 'PER_CADASTRO_AMBIENTES',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/Ambientes/AmbienteIndex.vue'),
      },
      {
        path: 'planos-assinatura',
        name: 'PlanosAssinatura',
        meta: {
          titulo: 'Planos de Assinatura',
          identificadorRecurso: 'CADASTRO_PLANOS_ASSINATURA',
          identificadorPermissao: 'PER_CADASTRO_PLANOS_ASSINATURA',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/PlanosAssinatura/PlanoAssinaturaIndex.vue'),
      },
      {
        path: 'controle-assinaturas',
        name: 'ControleAssinatura',
        meta: {
          titulo: 'Controle de Assinaturas',
          identificadorRecurso: 'CONTROLE_ASSINATURAS',
          identificadorPermissao: 'AUT_CONTROLE_ASSINATURAS',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/ControleAssinaturas/ControleAssinaturaIndex.vue'),
      },
      {
        path: 'sincronizar-ambientes',
        name: 'SincronizarAmbientes',
        meta: {
          titulo: 'Sincronizar Ambientes',
          identificadorRecurso: 'SINCRONIZAR_AMBIENTES',
          identificadorPermissao: 'AUT_SINCRONIZAR_AMBIENTES',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/SincronizarAmbientes/SincronizarAmbientesIndex.vue'),
      },
      {
        path: 'curadoria-cadastros',
        name: 'CuradoriaCadastros',
        meta: {
          titulo: 'Curadoria de Cadastros',
          identificadorRecurso: 'CURADORIA_CADASTROS',
          identificadorPermissao: 'AUT_CURADORIA_CADASTROS',
        },
        component: () => import(/* webpackChunkName: "painel-administrativo" */ '@/views/PainelAdministrativo/CuradoriaCadastros/CuradoriaCadastroIndex.vue'),
      },
    ],
  },
];

export default rotasModuloPainelAdministrativo;
